import React, { useEffect, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectUseCasesState, useUseCasesSlice } from '../../slice';
import { followUpDecoratorSchema } from './validationSchema';
import FormFieldBlock from '../../../../components/FormFieldBlock';
import CountryField from './fields/CountryField';
import OffenseCodeField from './fields/OffenseCodeField';
import OffenseTypeField from './fields/OffenseTypeField';
import LabelToggle from '../../../../../../Ticket/Cards/components/LabelToggle';
import FormFieldSmall from '../../../../components/FormFieldSmall';

function FollowUpDecorator({ outerRef, editEnabled, persistPipelineProcessor }) {
  const { countries, selectedProcessor, offenseCodes } = useSelector(selectUseCasesState);
  const { actions } = useUseCasesSlice();
  const offense_types = [
    {
      id: 'fiscal',
      name: I18n.t('settings.pipelines.processors.form.follow_up_decorator.fu_offense_type_fiscal')
    },
    {
      id: 'juridical',
      name: I18n.t(
        'settings.pipelines.processors.form.follow_up_decorator.fu_offense_type_juridical'
      )
    },
    {
      id: 'taxi',
      name: I18n.t('settings.pipelines.processors.form.follow_up_decorator.fu_offense_type_taxi')
    }
  ];

  // countries
  const selectedCountries = selectedProcessor?.config?.follow_up_rule?.country_codes
    ? selectedProcessor?.config?.follow_up_rule?.country_codes?.map(code => ({
        id: code,
        name: code
      }))
    : [{ id: 'NL', name: 'NL' }];

  const availableCountries = countries?.filter(
    country => !selectedCountries?.find(selectedCountry => selectedCountry.id === country.id)
  );

  // offense codes
  const selectedOffenseCodes = selectedProcessor?.config?.follow_up_rule?.offense_codes
    ? selectedProcessor?.config?.follow_up_rule?.offense_codes?.map(code => ({
        id: code,
        name: code
      }))
    : [];

  const availableOffenseCodes = offenseCodes
    ?.filter(oc => !selectedOffenseCodes?.find(selectedCode => selectedCode.id === oc.offenseCode))
    .map(c => ({ id: c.offenseCode, dbId: c.id, name: c.offenseCode }))
    .sort((a, b) => a.name > b.name);

  // offense types
  const selectedOffenseTypes = selectedProcessor?.config?.follow_up_rule?.offense_types
    ? selectedProcessor?.config?.follow_up_rule?.offense_types?.map(type => ({
        id: type,
        name: type
      }))
    : [];

  const availableOffenseTypes = offense_types?.filter(
    ot => !selectedOffenseTypes?.find(selectedType => selectedType.id === ot.id)
  );

  const formik = useFormik({
    initialValues: {
      id: selectedProcessor?.id,
      name: selectedProcessor?.name,
      manual_follow_up: selectedProcessor?.config?.manual_follow_up ?? false,
      follow_up_rule: true,
      follow_up_ttl: selectedProcessor?.config?.follow_up_ttl ?? '',
      assigned_ttl: selectedProcessor?.config?.assigned_ttl ?? '',
      makes_follow_up_required: selectedProcessor?.config?.makes_follow_up_required ?? '',
      enabledCountries: !!selectedProcessor?.config?.follow_up_rule?.country_codes?.length,
      selectedCountries,
      availableCountries,
      enabledOffenseCodes: !!selectedProcessor?.config?.follow_up_rule?.offense_codes?.length,
      selectedOffenseCodes,
      availableOffenseCodes,
      enabledOffenseTypes: !!selectedProcessor?.config?.follow_up_rule?.offense_types?.length,
      selectedOffenseTypes,
      availableOffenseTypes
    },
    enableReinitialize: true,
    onSubmit: values => {
      onSave(
        // eslint-disable-next-line no-undef
        _.pick(values, [
          'id',
          'name',
          'makes_follow_up_required',
          'follow_up_ttl',
          'assigned_ttl',
          'manual_follow_up',
          values['enabledCountries'] ? 'selectedCountries' : null,
          values['enabledOffenseCodes'] ? 'selectedOffenseCodes' : null,
          values['enabledOffenseTypes'] ? 'selectedOffenseTypes' : null
        ])
      );
    },
    validationSchema: followUpDecoratorSchema
  });

  useImperativeHandle(outerRef, () => {
    return {
      handleSave: () => formik.submitForm(),
      handleCancel: () => formik.resetForm(),
      handleChange: formik.handleChange
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setErrors(formik.errors));
  }, [formik.errors]);

  const onSave = async values => {
    let follow_up_rule = {};

    if (values.selectedCountries?.length > 0) {
      follow_up_rule.country_codes = values.selectedCountries?.map(c => c.id);
    }

    if (values.selectedOffenseTypes?.length > 0) {
      follow_up_rule.offense_types = values.selectedOffenseTypes?.map(ot => ot.id);
    }

    if (values.selectedOffenseCodes?.length > 0) {
      follow_up_rule.offense_codes = values.selectedOffenseCodes?.map(oc => oc.id);
    }

    const params = {
      id: values.id,
      name: values.name,
      config: {
        follow_up_rule,
        makes_follow_up_required: values.makes_follow_up_required,
        follow_up_ttl: values.follow_up_ttl,
        assigned_ttl: values.assigned_ttl,
        manual_follow_up: values.manual_follow_up
      }
    };

    await persistPipelineProcessor({ variables: params });
  };

  return (
    <div className={selectedProcessor?.className ?? ''}>
      <div className="content">
        <FormFieldBlock
          field={
            <>
              <div className="alert alert-primary" role="alert">
                {I18n.t('settings.pipelines.processors.messages.on_error_continue')}
              </div>
            </>
          }
        />

        <FormFieldSmall
          label={I18n.t('settings.pipelines.processors.form.follow_up_decorator.fu_manual')}
          field={
            <LabelToggle
              id="manual_follow_up"
              name="manual_follow_up"
              isOn={formik.values.manual_follow_up}
              onChange={formik.handleChange}
              disabled={!editEnabled}
            />
          }
          error={formik.errors.manual_follow_up}
        />

        <FormFieldBlock
          label={I18n.t('settings.pipelines.processors.form.follow_up_decorator.fu_required')}
          className="makes_follow_up_required"
          field={
            <select
              id="makes_follow_up_required"
              name="makes_follow_up_required"
              disabled={!editEnabled}
              onChange={formik.handleChange}
              value={formik.values.makes_follow_up_required}
            >
              <option value=""></option>
              <option value="if_matches">
                {I18n.t('settings.pipelines.processors.form.follow_up_decorator.fu_if_matches')}
              </option>
              <option value="unless_matches">
                {I18n.t('settings.pipelines.processors.form.follow_up_decorator.fu_unless_matches')}
              </option>
            </select>
          }
          error={formik.errors.makes_follow_up_required}
        />

        <FormFieldBlock
          field={
            <>
              <label className="left-label">
                {I18n.t('settings.pipelines.processors.form.follow_up_decorator.fu_ttl')}
              </label>
              <input
                id="follow_up_ttl"
                name="follow_up_ttl"
                type="number"
                value={formik.values.follow_up_ttl ?? ''}
                disabled={!editEnabled}
                className="small-input"
                onChange={formik.handleChange}
              />
              <div className="right-label">
                {I18n.t(
                  'settings.pipelines.processors.form.follow_up_decorator.fu_ttl_description'
                )}
              </div>
            </>
          }
          error={formik.errors.follow_up_ttl}
        />

        <FormFieldBlock
          field={
            <>
              <label className="left-label">
                {I18n.t('settings.pipelines.processors.form.follow_up_decorator.fu_assigned_ttl')}
              </label>
              <input
                id="assigned_ttl"
                name="assigned_ttl"
                type="number"
                value={formik.values.assigned_ttl ?? ''}
                className="small-input"
                disabled={!editEnabled}
                onChange={formik.handleChange}
              />
              <div className="right-label">
                {I18n.t(
                  'settings.pipelines.processors.form.follow_up_decorator.fu_assigned_ttl_description'
                )}
              </div>
            </>
          }
          error={formik.errors.assigned_ttl}
        />

        <FormFieldBlock
          label={I18n.t('settings.pipelines.processors.form.follow_up_decorator.fu_rule')}
          className="follow_up_rule"
          error={formik.errors.follow_up_rule}
        />

        <CountryField
          formik={formik}
          switchLabel={I18n.t(
            'settings.pipelines.processors.form.follow_up_decorator.fu_country_code'
          )}
          editEnabled={editEnabled}
          isSwitchable={true}
        />

        <OffenseCodeField
          formik={formik}
          switchLabel={I18n.t(
            'settings.pipelines.processors.form.follow_up_decorator.fu_offense_code'
          )}
          editEnabled={editEnabled}
          isSwitchable={true}
        />

        <OffenseTypeField
          formik={formik}
          switchLabel={I18n.t(
            'settings.pipelines.processors.form.follow_up_decorator.fu_offense_type'
          )}
          editEnabled={editEnabled}
          isSwitchable={true}
        />
      </div>
    </div>
  );
}

export default FollowUpDecorator;
