import React, { useState } from 'react';
import RunIncidentalPipelineModal from './RunIncidentalPipelineModal';
import { Button } from 'brickyard-ui';

const RunIncidentalPipelineButton = ({ count, params, useCaseIds }) => {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button variant="by-primary" onClick={() => setShowModal(true)}>
        {I18n.t('observations.observations.search.manual_pipeline_button')}
      </Button>

      {showModal && (
        <RunIncidentalPipelineModal
          count={count}
          params={params}
          showModal={showModal}
          useCaseIds={useCaseIds}
          onCloseModal={onCloseModal}
        />
      )}
    </>
  );
};

export default RunIncidentalPipelineButton;
