import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import LeftMenu from '../LeftMenu';
import Map from '../../shared/maps/Map';
import { selectTrackingState, useTrackingSlice } from './slice';
import { MarkerClusterer } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import InfoMarker from './components/InfoMarker';

const StyledTrackingPage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .tracking-page {
    display: flex;
    width: calc(100% - 80px);
    height: 100%;
    flex-direction: row;
    background-color: #f6f8fb;

    #google-map {
      min-width: 0px;
      border-radius: 4px;
      margin: 10px 10px 0px 10px;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
    }
  }
`;

const Tracking = () => {
  const dispatch = useDispatch();
  const [map, setMap] = useState();
  const { actions } = useTrackingSlice();
  const mapSet = useRef(false);

  const devices = useSelector(selectTrackingState).devices;

  useEffect(() => {
    dispatch(actions.fetchDevices());
    const fetchDataInterval = setInterval(() => {
      dispatch(actions.fetchDevices());
    }, 10000);

    return () => {
      clearInterval(fetchDataInterval);
      dispatch(actions.clearData());
    };
  }, []);

  useEffect(() => {
    if (map) {
      if (!mapSet.current) {
        // Netherlands
        map.setCenter({ lat: 52.1611973294726, lng: 5.621868260475858 });
      }

      if (devices.length) {
        const bounds = new window.google.maps.LatLngBounds();

        devices.forEach(device => {
          bounds.extend({ lat: parseFloat(device.latitude), lng: parseFloat(device.longitude) });
        });

        if (!mapSet.current) {
          !bounds.isEmpty() && map.fitBounds(bounds);
          mapSet.current = true;
        }
      }
    }
  }, [map, devices]);

  return (
    <StyledTrackingPage>
      <LeftMenu />
      <div className="tracking-page">
        <Map onLoad={setMap}>
          <MarkerClusterer zoomOnClick minimumClusterSize={2}>
            {clusterer =>
              devices.map(device => (
                <InfoMarker key={device.device_id} device={device} clusterer={clusterer} />
              ))
            }
          </MarkerClusterer>
        </Map>
      </div>
    </StyledTrackingPage>
  );
};

export default Tracking;
