import React from 'react';
import { InfoLabel, PopupMenu } from 'brickyard-ui';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, gql } from '@apollo/client';
import { Button, Row, Col } from 'brickyard-ui';
import { setCurrentObservation } from '../../actions/observationActions';

export const MARK_USE_CASE_RESULT_FOR_FOLLOW_UP = gql`
  mutation markUseCaseResultForFollowUp($id: ID!) {
    markUseCaseResultForFollowUp(id: $id) {
      id
      needsFollowUp
      manualFollowUp
    }
  }
`;

const UseCaseResultRelationHeader = ({
  info,
  unrelateAll,
  relateAll,
  deleteAll,
  deleteUnrelated,
  useCaseName = false,
  canUseCaseResultBeEdited = false,
  useCaseResultId,
  needsFollowUp,
  manualFollowUp
}) => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.observations.filters);

  const RelateAllItem = () => (
    <div onClick={closePopupAfter(relateAll)}>
      {I18n.t('observations.observations.details.actions.relate_all')}
    </div>
  );
  const UnrelateAllItem = () => (
    <div onClick={closePopupAfter(unrelateAll)}>
      {I18n.t('observations.observations.details.actions.unrelate_all')}
    </div>
  );
  const DeleteAllItem = () => (
    <div onClick={closePopupAfter(deleteAll)}>
      {I18n.t('observations.observations.details.actions.delete_all')}
    </div>
  );
  const DeleteUnrelatedItem = () => (
    <div onClick={closePopupAfter(deleteUnrelated)}>
      {I18n.t('observations.observations.details.actions.delete_unrelated')}
    </div>
  );

  const closePopupAfter = action => () => {
    action();
    document.body.click();
  };

  const actions = [
    { render: RelateAllItem },
    { render: UnrelateAllItem },
    { render: DeleteAllItem },
    { render: DeleteUnrelatedItem }
  ];

  const completeMutation = () => {
    dispatch(setCurrentObservation(''));
  };

  const [markUseCaseResultForFollowUp] = useMutation(MARK_USE_CASE_RESULT_FOR_FOLLOW_UP, {
    variables: { id: useCaseResultId },
    ignoreResults: true,
    onCompleted: completeMutation
  });

  const onManualFollowUpClick = () => {
    markUseCaseResultForFollowUp();
  };

  return (
    <div className="ucr-relation-header">
      <Row noGutters>
        <Col md={10}>
          <h5>{useCaseName}</h5>
        </Col>

        <Col>
          {manualFollowUp && !needsFollowUp && (
            <Button variant="primary" size="sm" onClick={() => onManualFollowUpClick()}>
              {I18n.t('settings.pipelines.processors.form.follow_up_decorator.fu_button')}
            </Button>
          )}
        </Col>
      </Row>

      <InfoLabel title={I18n.t('scancar.observations.index.source')} text={info} />
      {!filters.validatedFilter && (
        <div className="ucr-relation-header-title">
          {canUseCaseResultBeEdited ? (
            <PopupMenu placement="bottom-start" actions={actions} rootClose={true} />
          ) : (
            <span style={{ width: '5px' }} />
          )}
          <h6 className={'normal'}>
            {I18n.t('observations.observations.details.vehicle_observations')}
          </h6>
        </div>
      )}
    </div>
  );
};

export default UseCaseResultRelationHeader;
