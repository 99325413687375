import { Query } from '@apollo/client/react/components';
import React from 'react';
import { connect } from 'react-redux';
import {
  defaultFilters,
  getFilterTransformer,
  I18N_FIELD_MAPPING
} from './SearchObservationDialog';
import {
  Loading,
  Row,
  Col,
  Form,
  DatePicker,
  Button,
  Badge,
  ContinuousPagination
} from 'brickyard-ui';

import { ObjectToQuery, URLToObject } from '@/utils/URLQueryParser';
import TestBoundary from '@/utils/TestBoundary';
import { replaceVehicleTypes } from '../scenes/TicketsTable/utils';
import UseCaseResultTable from '../Cop/UseCaseResultTable';
import UseCaseResultsMetadataSetter from '../Cop/UseCaseResultsMetadataSetter';
import UseCaseResultContainer from './UseCaseResultContainer';
import SearchObservationDialog from './SearchObservationDialog';
import UseCaseResultProcessSummary from '../Cop/UseCaseResultProcessSummary';
import DeleteAllCopUseCaseResultBtn from './DeleteAllCopUseResultBtn';
import { removeObservationMetadata, cleanMetadata } from '@/actions/observationActions';

import {
  GET_USE_CASE_RESULTS,
  GET_USE_CASE_RESULT_COUNT
} from '@/components/queries/cop/use_case_results';
import { GET_VEHICLE_TYPES } from '@/components/queries/SharedQueries';

import 'styles/scenes/use_cases.scss';
import { setCurrentObservation } from '../../actions/observationActions';
import { any, prepend, propEq } from 'ramda';
import { setObservationsFilters } from '../../actions/observationsActions';
import RunIncidentalPipelineButton from './RunIncidentalPipelineButton';

const keyMap = {
  observedAt: 'OBSERVATION_OBSERVED_AT',
  camera: 'OBSERVATION_CAMERA_NAME',
  offenseCode: 'OFFENSE_CODE',
  vehicleLicensePlateNumber: 'OBSERVATION_LICENSE_PLATE',
  accuracy: 'OBSERVATION_ACCURACY',
  countryCode: 'OBSERVATION_COUNTRY_CODE',
  location: 'OFFENSE_LOCATION_CITY'
};

class UseCaseResultsContainer extends React.Component {
  useCaseIds = [];

  constructor(props) {
    super(props);
    this.state = {
      environmental: false,
      filters: { ...defaultFilters },
      licensePlateValue: '',
      vehicleTypeValue: '',
      observationObservedAtGteq: '',
      observationObservedAtLteq: '',
      showAdvancedFilters: false,
      showProcessSummary: false,
      sortObj: { key: 'observationObservedAt', order: 'asc' },
      sortBy: 'OBSERVATION_OBSERVED_AT_ASC',
      fullSize: null,
      compactSize: null,
      cursorMap: []
    };
  }

  componentDidMount() {
    this.setPaginationSizes();
    this.setFiltersFromURL();

    this.useCaseIds = this.getUseCaseIds();
    document.addEventListener('keydown', this.handleArrowKeys);
  }

  componentDidUpdate(prevProps, prevState) {
    const { openObsId, showMap, pagination, updateMap } = this.props;
    const { fullSize, compactSize, cursorMap, sortBy, filters } = this.state;

    if (
      prevProps.useCase !== this.props.useCase ||
      prevProps.suspectList !== this.props.suspectList
    ) {
      this.useCaseIds = this.getUseCaseIds();
    }

    if (prevState.sortBy !== sortBy) {
      updateMap(sortBy);
    }

    if (prevProps.openObsId === '' && openObsId !== '' && !showMap) {
      const openedIdx = cursorMap.findIndex(edge => edge.id === openObsId);

      if (openedIdx >= compactSize) {
        pagination.next(cursorMap[openedIdx - ~~(compactSize / 2)].cursor);
      }

      pagination.setSize(compactSize);
    }
    if (prevProps.openObsId !== '' && openObsId === '' && !showMap) {
      pagination.setSize(fullSize);
      pagination.start();
    }
    if (prevProps.showMap !== showMap) {
      pagination.setSize(showMap ? compactSize : fullSize);
      pagination.start();
    }

    if (prevState.filters !== filters) {
      this.props.setObservationsFilters(filters);
    }

    this.setURL();
  }

  handleArrowKeys = event => {
    const { openObsId } = this.props;
    const { cursorMap } = this.state;

    if ((event.keyCode !== 38 && event.keyCode !== 40) || !cursorMap.length) {
      return;
    }

    const openedIdx = cursorMap.findIndex(edge => edge.id === openObsId);

    if (event.keyCode === 38) {
      this.props.setCurrentObservation(cursorMap[Math.max(openedIdx - 1, 0)].id);
    }
    if (event.keyCode === 40) {
      this.props.setCurrentObservation(cursorMap[Math.min(openedIdx + 1, cursorMap.length - 1)].id);
    }
  };

  setPaginationSizes = () => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const { pagination, type } = this.props;

    const containerHeight = document.querySelector('#observations').clientHeight;
    const topComponentHeight = type === 'c01' ? 260 : 360;
    const otherComponentsHeight = 295;
    const rowHeight = 32;

    this.setState(
      {
        fullSize: parseInt((containerHeight - otherComponentsHeight) / rowHeight),
        compactSize: parseInt(
          (containerHeight - topComponentHeight - otherComponentsHeight) / rowHeight
        )
      },
      () => pagination.setSize(this.state.compactSize)
    );
  };

  setFiltersFromURL = () => {
    const urlFilters = URLToObject(window.location);
    const onlyFiltersFromURL = Object.keys(urlFilters)
      .filter(key => Object.keys(this.state.filters).includes(key))
      .reduce((filters, key) => ({ ...filters, [key]: urlFilters[key] }), {});

    this.setState({
      filters: {
        ...this.state.filters,
        ...onlyFiltersFromURL,
        observationObservedAtGteq: urlFilters.observationObservedAtGteq || '',
        observationObservedAtLteq: urlFilters.observationObservedAtLteq || ''
      },
      licensePlateValue: onlyFiltersFromURL.observationLicensePlateCont || '',
      vehicleTypeValue: onlyFiltersFromURL.vehicleTypeMatches || '',
      observationObservedAtGteq: urlFilters.observationObservedAtGteq || '',
      observationObservedAtLteq: urlFilters.observationObservedAtLteq || '',
      sortBy: urlFilters.sortBy || this.state.sortBy
    });

    if (urlFilters.sortBy) {
      const key = Object.keys(keyMap).find(k => urlFilters.sortBy.startsWith(keyMap[k]));
      if (key) {
        const order = urlFilters.sortBy.endsWith('_ASC') ? 'asc' : 'desc';
        this.setState({ sortObj: { key, order } });
      }
    }

    const { pagination } = this.props;

    if (urlFilters.before) {
      pagination.previous(urlFilters.before);
    } else if (urlFilters.after) {
      pagination.next(urlFilters.after);
    }

    if (urlFilters.openObsId) {
      this.props.setCurrentObservation(urlFilters.openObsId);
    }
  };

  getUseCaseIds = () => {
    const { useCase, suspectList } = this.props;

    return suspectList ? suspectList.useCases.map(useCase => useCase.id) : [useCase?.id];
  };

  setURL = () => {
    const { openObsId, pagination } = this.props;
    const { filters, sortBy } = this.state;
    const { before, after } = pagination;

    window.history.pushState(
      {},
      null,
      ObjectToQuery({
        ...filters,
        useCaseId: this.getUseCaseIds(),
        sortBy,
        openObsId: openObsId || null,
        before,
        after
      })
    );
  };

  setCurrentSearch = currentSearchList => {
    this.props.pagination.start();
    this.setState({
      filters: currentSearchList,
      licensePlateValue: currentSearchList.observationLicensePlateCont,
      vehicleTypeValue: currentSearchList.vehicleTypeMatches,
      observationObservedAtGteq: currentSearchList.observationObservedAtGteq,
      observationObservedAtLteq: currentSearchList.observationObservedAtLteq
    });
  };

  clearFilters = () => {
    this.setState({
      filters: defaultFilters,
      licensePlateValue: '',
      vehicleTypeValue: '',
      observationObservedAtGteq: '',
      observationObservedAtLteq: ''
    });
  };

  dismissBadge = key => {
    if (key === 'observationObservedAtGteq' || key === 'observationObservedAtLteq') {
      this.setState({ [key]: null });
    }
    if (key === 'exemptFilter' || key === 'delayedFilter' || key === 'validatedFilter') {
      this.setState({ filters: { ...this.state.filters, [key]: false } });
    } else {
      if (key === 'observationLicensePlateCont') {
        this.setState({ licensePlateValue: '' });
      }
      if (key === 'vehicleTypeMatches') {
        this.setState({ vehicleTypeValue: '' });
      }
      this.setState({ filters: { ...this.state.filters, [key]: '' } });
    }
  };

  afterProcess = cb => {
    this.props.cleanMetadata();
    cb();
  };

  sortTable = (key, order) => {
    this.setState({
      sortBy: `${keyMap[key]}_${order.toUpperCase()}`,
      sortObj: { key, order }
    });
  };

  onResetAll = refetch => {
    this.setState({ showProcessSummary: false });
    this.props.cleanMetadata();
    refetch();
  };

  onReset = (id, refetch) => {
    this.setState({ showProcessSummary: false });
    this.props.removeObservationMetadata(id);
    refetch();
  };

  removeNull = () => {
    const { filters } = this.state;
    return Object.keys(filters).reduce(
      (removed, key) => (filters[key] ? { ...removed, [key]: filters[key] } : removed),
      {}
    );
  };

  onDeleteAll = refetch => {
    this.props.pagination.start();
    this.setState({
      filters: { ...defaultFilters }
    });
    refetch();
  };

  onCompleted = data => {
    this.setState({
      cursorMap: data.useCaseResults.edges.map(edge => ({
        id: edge.node.id,
        cursor: edge.cursor
      }))
    });
  };

  isLastObservation = (observations, id) => {
    return observations.findIndex(obs => obs.node.id === id) === observations.length - 1;
  };

  stringToOption = value => {
    return { id: value, name: value };
  };

  idMatches = () => {
    return propEq('id');
  };

  getOptions = (data, key, value) => {
    const array = data ? data[key] : [];

    if (!value || any(this.idMatches(value), array)) {
      return array;
    }

    return prepend(this.stringToOption(value), array);
  };

  shouldRenderDeleteAllBtn = (data, filters) => {
    return (
      data &&
      !filters.validatedFilter &&
      data.useCaseResults.edges[0].node.useCaseResultPermission ==
        'USE_CASE_RESULT_PERMISSION_WRITE'
    );
  };

  render() {
    const {
      useCase,
      suspectList,
      camera,
      unselectCam,
      location,
      unselectLocation,
      metadata,
      openObsId,
      pagination,
      type,
      user
    } = this.props;
    const {
      showAdvancedFilters,
      showProcessSummary,
      filters,
      sortObj,
      licensePlateValue,
      vehicleTypeValue,
      observationObservedAtGteq,
      observationObservedAtLteq
    } = this.state;
    const searchObj = {
      ...this.removeNull(),
      useCaseId: this.getUseCaseIds(),
      statusIn: ['observed', 'validated', 'marked_for_deletion'],
      s: this.state.sortBy
    };

    if (location) searchObj.cameraLocationEq = location.id;
    if (camera) searchObj.cameraIdEq = camera.id;

    return (
      <Query
        query={GET_USE_CASE_RESULTS}
        variables={{
          first: pagination.first,
          last: pagination.last,
          after: pagination.after,
          before: pagination.before,
          search: searchObj
        }}
        pollInterval={3000}
        onCompleted={this.onCompleted}
      >
        {({ loading, error, data, refetch, variables, startPolling, stopPolling }) => (
          <main className="observations-container">
            <UseCaseResultsMetadataSetter
              selectedUseCases={this.useCaseIds}
              validatedFilter={filters.validatedFilter}
            />
            {!!openObsId && data && (
              <section className="ucr-details-container">
                <TestBoundary>
                  <UseCaseResultContainer
                    useCase={useCase}
                    useCaseIds={this.getUseCaseIds()}
                    suspectList={suspectList}
                    onReset={id => this.onReset(id, refetch)}
                    onStartPolling={stopPolling}
                    onStopPolling={startPolling}
                    type={type}
                    validatedFilter={filters.validatedFilter}
                    isLastObservation={this.isLastObservation(data.useCaseResults.edges, openObsId)}
                    shiftPagination={() =>
                      pagination.next(data.useCaseResults.pageInfo.startCursor)
                    }
                    hasNextPage={data.useCaseResults.pageInfo.hasNextPage}
                    reloadAll={() => refetch()}
                  />
                </TestBoundary>
              </section>
            )}
            {!openObsId && (
              <section className="search">
                <Row className="align-items-end">
                  <Col sm="2">
                    <Form.Group>
                      <Form.Label>
                        {I18n.t('observations.observations.list.license_plate')}
                      </Form.Label>
                      <Form.Control
                        value={licensePlateValue}
                        onChange={e => {
                          this.setState({ licensePlateValue: e.target.value.toUpperCase() });
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm="2">
                    <Form.Group>
                      <Form.Label>
                        {I18n.t('observations.observations.search.vehicle_type_select_placeholder')}
                      </Form.Label>
                      <Query
                        query={GET_VEHICLE_TYPES}
                        variables={{
                          needRdwConst: true
                        }}
                      >
                        {({ loading, error, data }) => {
                          if (loading || error) return null;
                          return (
                            <Form.Control
                              as="select"
                              value={vehicleTypeValue}
                              onChange={e => {
                                this.setState({ vehicleTypeValue: e.target.value });
                              }}
                            >
                              <option value="" />
                              {data.vehicleTypes.map(vt => (
                                <option key={vt.name} value={vt.name}>
                                  {vt.name}
                                </option>
                              ))}
                            </Form.Control>
                          );
                        }}
                      </Query>
                    </Form.Group>
                  </Col>
                  <Col sm="2">
                    <Form.Group>
                      <Form.Label>
                        {I18n.t(`observations.observations.search.observed_from`)}
                      </Form.Label>
                      <DatePicker
                        showTimePicker
                        id="gte"
                        value={observationObservedAtGteq}
                        onChange={date => {
                          this.setState({ observationObservedAtGteq: date.toISOString() });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="2">
                    <Form.Group>
                      <Form.Label>
                        {I18n.t(`observations.observations.search.observed_until`)}
                      </Form.Label>
                      <DatePicker
                        showTimePicker
                        id="lte"
                        value={observationObservedAtLteq}
                        onChange={date => {
                          this.setState({ observationObservedAtLteq: date.toISOString() });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="auto">
                    <Form.Group>
                      <Button
                        variant="light-by-primary"
                        onClick={() => this.setState({ showAdvancedFilters: true })}
                      >
                        {I18n.t('observations.observations.search.advanced_filters')}
                      </Button>
                    </Form.Group>
                  </Col>

                  {user.cop_run_pipeline_manually === true && !suspectList && (
                    <Col sm="auto" className="ml-auto">
                      <Form.Group>
                        <RunIncidentalPipelineButton
                          count={data?.useCaseResults.totalCount}
                          params={variables.search}
                          useCaseIds={this.useCaseIds}
                        />
                      </Form.Group>
                    </Col>
                  )}

                  <Col sm="auto" className="ml-auto">
                    <Form.Group>
                      <Button variant="light-by-primary" onClick={this.clearFilters}>
                        {I18n.t('observations.observations.search.clear_action')}
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col sm="auto" className="p-0">
                    <Form.Group>
                      <Button
                        variant="by-secondary"
                        onClick={() => {
                          this.setCurrentSearch({
                            ...filters,
                            observationLicensePlateCont: licensePlateValue,
                            vehicleTypeMatches: replaceVehicleTypes(vehicleTypeValue),
                            observationObservedAtGteq,
                            observationObservedAtLteq
                          });
                        }}
                      >
                        {I18n.t('observations.observations.search.search_action')}
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
                {data?.copMeta?.observationsCountries && data?.copMeta?.observationTypes && (
                  <SearchObservationDialog
                    countries={[...data.copMeta.observationsCountries]}
                    show={showAdvancedFilters}
                    filters={this.state.filters}
                    onHide={() => this.setState({ showAdvancedFilters: false })}
                    setCurrentSearch={this.setCurrentSearch}
                    type={data.copMeta.observationTypes[0]}
                  />
                )}
              </section>
            )}
            <section className="searching-badges">
              {(Object.values(filters).some(value => !!value) || camera || location) && (
                <div className="badges-container">
                  {camera && (
                    <span className="class-with-margin">
                      <Badge dismissable variant="by-secondary" onClose={() => unselectCam()}>
                        {I18n.t(`observations.observations.details.camera`)} : {camera.name}
                      </Badge>
                    </span>
                  )}
                  {location && (
                    <span className="class-with-margin">
                      <Badge dismissable variant="by-secondary" onClose={() => unselectLocation()}>
                        {I18n.t(`scancar.observations.search.location_placeholder`)} :{' '}
                        {location.name}
                      </Badge>
                    </span>
                  )}
                  {Object.entries(filters).map(
                    ([key, value]) =>
                      !!value && (
                        <span key={key} className="class-with-margin">
                          <Badge
                            dismissable
                            variant="by-secondary"
                            key={key}
                            onClose={() => {
                              this.dismissBadge(key);
                            }}
                          >
                            {I18N_FIELD_MAPPING[key]}: {getFilterTransformer(key)(value)}
                          </Badge>
                        </span>
                      )
                  )}
                </div>
              )}
            </section>
            <section className="observations-container">
              {(() => {
                if (loading) {
                  return <Loading />;
                }
                if (error) {
                  return <p>{error.toString()}</p>;
                }
                if (!data.useCaseResults.edges.length) {
                  return (
                    <p className="empty-message">
                      {I18n.t('observations.observations.messages.empty')}
                    </p>
                  );
                }

                return (
                  <>
                    <section className="delete-all">
                      <Query
                        query={GET_USE_CASE_RESULT_COUNT}
                        variables={{
                          search: {
                            ...searchObj,
                            onlyBackofficeEvaluatable: true
                          }
                        }}
                        pollInterval={3000}
                      >
                        {({ data: deletableResultsData }) => {
                          if (!deletableResultsData) return null;
                          if (
                            !this.shouldRenderDeleteAllBtn(data, filters) ||
                            deletableResultsData.useCaseResults.totalCount === 0
                          )
                            return null;
                          return (
                            <DeleteAllCopUseCaseResultBtn
                              onDelete={() => this.onDeleteAll(refetch)}
                              params={variables.search}
                              count={deletableResultsData.useCaseResults.totalCount}
                              needsReason={data.useCaseResults.edges.some(
                                obs => obs.node.useCase.dismissalReasonRequired
                              )}
                            />
                          );
                        }}
                      </Query>
                    </section>
                    <span>
                      {I18n.t('observations.observations.search.total', {
                        total: data.useCaseResults.totalCount
                      })}
                    </span>
                    <UseCaseResultTable
                      onSort={this.sortTable}
                      sorting={sortObj}
                      observations={data.useCaseResults.edges.map(edge => ({
                        ...edge.node,
                        action: metadata[edge.node.id] ? metadata[edge.node.id].action : '',
                        camera: edge.node.camera.name,
                        accuracy: edge.node.accuracy * 100
                      }))}
                      onReset={id => this.onReset(id, refetch)}
                      openObsId={openObsId}
                      type={type}
                    />
                    <div className="observation-table-footer">
                      <ContinuousPagination
                        hasPrevious={data.useCaseResults.pageInfo.hasPreviousPage}
                        hasNext={data.useCaseResults.pageInfo.hasNextPage}
                        onStart={pagination.start}
                        onPrevious={() =>
                          pagination.previous(data.useCaseResults.pageInfo.startCursor)
                        }
                        onNext={() => pagination.next(data.useCaseResults.pageInfo.endCursor)}
                        onEnd={pagination.end}
                      />
                      {!!Object.values(metadata).filter(
                        meta => !!meta.action && meta.validatedBy === user.id
                      ).length &&
                        !filters.validatedFilter && (
                          <Button
                            variant="by-primary"
                            onClick={() => this.setState({ showProcessSummary: true })}
                          >
                            {I18n.t('observations.observations.actions.process')} (
                            {
                              Object.values(metadata).filter(
                                meta => !!meta.action && meta.validatedBy === user.id
                              ).length
                            }
                            )
                          </Button>
                        )}
                    </div>

                    {!!Object.keys(metadata).length && (
                      <UseCaseResultProcessSummary
                        show={showProcessSummary}
                        observations={metadata}
                        onResetAll={() => this.onResetAll(refetch)}
                        onReset={id => this.onReset(id, refetch)}
                        onHide={() => this.setState({ showProcessSummary: false })}
                        onProcess={() => this.afterProcess(refetch)}
                        useCaseIds={this.getUseCaseIds()}
                      />
                    )}
                  </>
                );
              })()}
            </section>
          </main>
        )}
      </Query>
    );
  }
}

const mapStateToProps = state => ({
  metadata: state.observations,
  openObsId: state.observations.current,
  user: state.user
});

export default connect(mapStateToProps, {
  setCurrentObservation,
  setObservationsFilters,
  cleanMetadata,
  removeObservationMetadata
})(UseCaseResultsContainer);
