import { useQuery, useMutation, gql } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { forEach, isEmpty } from 'ramda';

import { Loading, Alert } from 'brickyard-ui';
import UseCaseResultTabs from './UseCaseResultTabs';
import DeleteReasonModal from '../shared/observations/DeleteReasonModal';

import { GET_DETAILED_USE_CASE_RESULT } from '@/components/queries/cop/use_case_results';

import {
  addObservationActionMetadata,
  addObservationRelationMetadata,
  removeObservationRelationMetadata,
  removeObservationMetadata,
  removeObservationAllRelationMetadata
} from '../../actions/observationActions';

import TestBoundary from '@/utils/TestBoundary';

import 'styles/shared/use_cases.scss';
import _ from 'lodash';

export const VALIDATE_ATTACH = gql`
  mutation ValidateAttach(
    $id: ID!
    $type: CopUseCaseResultValidationTypes!
    $attachments: [ID!]
    $reasonId: ID
    $exemptionTypeId: ID
    $comment: String
  ) {
    copValidateAndAttach(
      id: $id
      validatedType: $type
      attachmentIds: $attachments
      reasonId: $reasonId
      exemptionTypeId: $exemptionTypeId
      comment: $comment
    ) {
      id
      validatedType
      attachments {
        id
      }
    }
  }
`;

/**
 * For starting and stopping polling on this component, you need to do the oposite on
 * the table's parent component, otherwise it will conflict the data syncing.
 */
const UseCaseResultContainer = ({
  useCase,
  useCaseIds,
  suspectList,
  onReset,
  onStartPolling,
  onStopPolling,
  type,
  isLastObservation,
  shiftPagination,
  hasNextPage,
  reloadAll,
  validatedFilter
}) => {
  const observationId = useSelector(state => state.observations.current);
  const metadata = useSelector(state => state.observations);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [mainObservationId, setMainObservationId] = useState(observationId);
  const [showDismissalModal, setShowDismissalModal] = useState(false);
  const [useCaseResults, setUseCaseResults] = useState(null);

  /**
   * For selecting the dismissal reason we have two different "triggers":
   * - Delete button regarding the mainObsevation
   * - A related observation that will be deleted
   *
   * As the dismissal reason is set on a modal, for reusing the same flow
   * the dismissalObs is set to set the metadata after hiding the deleting
   * reason modal.
   *
   **/
  const [dismissalObservations, setDismissalObservations] = useState([]);

  const { loading, data, error, refetch, startPolling, stopPolling } = useQuery(
    GET_DETAILED_USE_CASE_RESULT,
    {
      variables: {
        id: observationId,
        useCaseId: suspectList ? suspectList.useCases.map(useCase => useCase.id) : [useCase?.id],
        validatedFilter
      }
    }
  );

  const [validateAttach] = useMutation(VALIDATE_ATTACH);

  const getMainObservation = () => {
    return useCaseResults && useCaseResults[0];
  };

  const relateAll = (mainObservation = null) => {
    const { status, relatedUseCaseResults } = mainObservation ?? getMainObservation();
    const observations = status === 'validated' ? [] : relatedUseCaseResults;

    changeRelation(
      'relate',
      observations.filter(obs => obs.id !== mainObservationId)
    );
  };

  const getObsStatus = obs => {
    if (metadata[obs.id] && _.size(metadata[obs.id]) > 0) {
      return metadata[obs.id].action || 'unrelated';
    }
    if (metadata[mainObservationId] && metadata[mainObservationId].relations) {
      return metadata[mainObservationId].relations.has(obs.id) ? 'related' : 'unrelated';
    }

    return 'unrelated';
  };

  const getObsRelatedStatus = obs => {
    if (metadata[mainObservationId] && metadata[mainObservationId].relations) {
      return metadata[mainObservationId].relations.has(obs.id) ? 'related' : 'unrelated';
    }

    return 'unrelated';
  };

  useEffect(() => {
    setMainObservationId(observationId);
  }, [observationId]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setUseCaseResults(structuredClone(data.useCaseResults.nodes));
    if (useCase.organisation.linkRelatedObservations) {
      relateAll(data.useCaseResults.nodes[0]);
    }

    if (data.useCaseResults.nodes.length) {
      startPolling(3000);
      onStartPolling();
    } else {
      stopPolling();
      onStopPolling(3000);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      stopPolling();
      onStopPolling(3000);
    };
  }, []);

  const switchMain = async obs => {
    setMainObservationId(obs.id);
    await refetch({ id: obs.id, useCaseId: useCaseIds });
    dispatch(removeObservationMetadata(mainObservationId));
  };

  const addToDelete = (obs, reasonId = null) => {
    if (getMainObservation().useCase.dismissalReasonRequired && !reasonId) {
      return;
    }
    if (getObsStatus(obs) === 'related') {
      unrelate(obs);
    }
    const promise = validateAttach({
      variables: {
        id: obs.id,
        type: 'DELETE',
        reasonId
      }
    });
    dispatch(
      addObservationActionMetadata({
        observationId: obs.id,
        action: 'delete',
        dismissalReason: reasonId,
        validatedBy: user.id
      })
    );

    return promise;
  };

  const relate = obs => {
    if (obs.id !== mainObservationId && !!metadata[obs.id]) {
      dispatch(removeObservationAllRelationMetadata(obs.id));
    }
    dispatch(addObservationRelationMetadata({ mainObservationId, relatedId: obs.id }));
  };

  const unrelate = obs => {
    dispatch(removeObservationRelationMetadata({ mainObservationId, relatedId: obs.id }));
  };

  const markTo = (action, validateAttach) => {
    const updateData = () => {
      reloadAll();
      dispatch(
        addObservationActionMetadata({
          observationId: mainObservationId,
          action,
          validatedBy: user.id
        })
      );
      if (isLastObservation && hasNextPage) {
        shiftPagination();
      }
    };
    if (action === 'ticket' || action === 'warning') {
      validateAttach({
        variables: {
          id: mainObservationId,
          type: action.toUpperCase(),
          attachments: Array.from(
            metadata[mainObservationId] ? metadata[mainObservationId].relations || [] : []
          )
        }
      }).then(() => updateData());
    } else {
      if (action?.type === 'exemption') {
        validateAttach({
          variables: {
            id: mainObservationId,
            type: action?.type.toUpperCase(),
            attachments: Array.from(
              metadata[mainObservationId] ? metadata[mainObservationId].relations || [] : []
            ),
            exemptionTypeId: parseInt(action?.exemptionTypeId),
            comment: action?.comment
          }
        }).then(() => updateData());
      }
    }
  };

  const changeRelation = (status, target) => {
    const statusController = {
      delete: observations => {
        if (getMainObservation().useCase.dismissalReasonRequired) {
          setDismissalObservations(observations);
          setShowDismissalModal(true);
        } else {
          Promise.all(
            observations.map(observation => {
              addToDelete(observation);
            })
          ).then(() => {
            reloadAll();
            refetch();
          });
        }
      },
      main: forEach(switchMain),
      relate: forEach(relate),
      unrelate: forEach(unrelate)
    };
    statusController[status](target);
  };

  const processDeleteWithReason = reasonId => {
    setShowDismissalModal(false);

    if (!reasonId || isEmpty(dismissalObservations)) {
      return;
    }

    Promise.all(
      dismissalObservations.map(observation => {
        return addToDelete(observation, reasonId);
      })
    ).then(() => {
      reloadAll();
      refetch();
    });

    setDismissalObservations([]);
  };

  if (loading) return <Loading />;
  if (error) return <p>{error.toString()}</p>;

  return (
    <>
      <div className="ucr-details">
        <TestBoundary>
          {getMainObservation() ? (
            <UseCaseResultTabs
              key={getMainObservation().id}
              mainObservation={getMainObservation()}
              getObsStatus={getObsStatus}
              getObsRelatedStatus={getObsRelatedStatus}
              markTo={action => markTo(action, validateAttach)}
              onRelationChange={changeRelation}
              onReset={onReset}
              useCase={useCase}
              type={type}
            />
          ) : (
            <Loading />
          )}
        </TestBoundary>
        {showDismissalModal && (
          <TestBoundary>
            <DeleteReasonModal
              show={showDismissalModal}
              onHide={() => setShowDismissalModal(false)}
              onSelect={processDeleteWithReason}
            />
          </TestBoundary>
        )}
      </div>
      {getMainObservation() && getMainObservation().pipelineFailed && (
        <Alert variant="danger" className="pipeline-failed">
          {I18n.t('use_case.errors.messages.pipeline_failed')}
        </Alert>
      )}
      {getMainObservation() && getMainObservation().showSuspect && (
        <Alert variant="warning" className="pipeline-failed">
          {I18n.t('use_case.errors.messages.show_suspect')}
        </Alert>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(UseCaseResultContainer);
