import React from 'react';
import * as Yup from 'yup';
import styled from 'styled-components';
import FormFieldBlock from '../scenes/Cop/Settings/components/FormFieldBlock';
import { useFormik } from 'formik';
import { Button, Modal, Loading } from 'brickyard-ui';
import { useQuery, useMutation, gql } from '@apollo/client';
import { GET_USE_CASE_INCIDENTAL_PIPELINES } from '../queries/cop/use_case_results';
import { cleanMetadata } from '@/actions/observationActions';
import { useDispatch } from 'react-redux';

const IncidentalPipelineModalStyle = styled.div`
  .incidental-pipeline-block {
    margin: 0;
  }
`;

export const RUN_PIPELINE_MANUALLY = gql`
  mutation runPipelineManually($search: UseCaseResultSearch!, $incidentalPipelineId: Int!) {
    runPipelineManually(search: $search, incidentalPipelineId: $incidentalPipelineId) {
      count
    }
  }
`;

const RunIncidentalPipelineModal = ({ count, params, showModal, useCaseIds, onCloseModal }) => {
  const dispatch = useDispatch();
  const { loading, data } = useQuery(GET_USE_CASE_INCIDENTAL_PIPELINES, {
    variables: { id: useCaseIds[0] }
  });

  const completeMutation = () => {
    dispatch(cleanMetadata());
    onCloseModal();
  };

  const [runPipelineManually] = useMutation(RUN_PIPELINE_MANUALLY, {
    ignoreResults: true,
    onCompleted: completeMutation
  });

  const onSubmit = async values => {
    try {
      await runPipelineManually({
        variables: { search: params, incidentalPipelineId: parseInt(values.incidentalPipeline) }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      incidentalPipeline: null
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      incidentalPipeline: Yup.string()
        .required(I18n.t('observations.observations.search.manual_pipeline_validation'))
        .nullable()
    }),
    onSubmit
  });

  const handleSave = () => {
    formik.submitForm();
  };

  const handleCancel = () => {
    formik.resetForm();
    onCloseModal();
  };

  const message = I18n.t(
    'observations.observations.search.manual_pipeline_modal_description_line_2',
    {
      count
    }
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Modal
          show={showModal}
          onHide={handleCancel}
          size="md"
          className="observation-manual-recheck-confirmation"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {I18n.t('observations.observations.search.manual_pipeline_modal_title')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <IncidentalPipelineModalStyle>
              <b className="text-danger">
                {I18n.t(
                  'observations.observations.search.manual_pipeline_modal_description_line_1'
                )}
              </b>

              <p className="text-danger">{message}</p>

              {loading ? (
                <Loading size="sm" variant="by-dark" />
              ) : (
                <FormFieldBlock
                  className="incidental-pipeline-block"
                  label={I18n.t(
                    'observations.observations.search.manual_pipeline_modal_select_title'
                  )}
                  field={
                    <select
                      className="form-control"
                      id="incidentalPipeline"
                      name="incidentalPipeline"
                      onChange={formik.handleChange}
                    >
                      <option></option>
                      {data?.useCaseIncidentalPipelines.map(pipeline => {
                        return (
                          <option key={pipeline.id} value={pipeline.id}>
                            {pipeline.name}
                          </option>
                        );
                      })}
                    </select>
                  }
                  error={formik.errors.incidentalPipeline}
                />
              )}
            </IncidentalPipelineModalStyle>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="by-dark" onClick={() => handleCancel()}>
              {I18n.t('actions.cancel')}
            </Button>

            <Button variant="by-primary" onClick={() => handleSave()}>
              {I18n.t('observations.observations.search.manual_pipeline_modal_button')}
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </>
  );
};

export default RunIncidentalPipelineModal;
